<template>
  <div>
    <div class="app-message-content received">
      <div class="app-message-text">
        <p>
          ...
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "waiting-message",
  components: {
  },
  data: function () {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
  .app-message-content {
    margin: 6px 10px;
    width: 100%;
    display: flex;
  }
  .question-active {
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
  .app-message-content:first-of-type {
    margin-top: 30px;
  }
  .app-message-content:last-of-type {
    margin-bottom: 30px;
  }
  .app-message-content.sent {
    justify-content: flex-end;
  }
  .app-message-content.sent .app-message-avatar {
    display: none;
  }
  .app-message-text {
    padding: 18px 20px;
    border-radius: 6px;
    width: 100px;
  }
  .app-message-text p {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    word-wrap: break-word;
    margin: 0;
    font-family: arial;
  }
  .spinner {
    width: 100px;
  }
</style>
