<template>
  <div class="answer-choices">
    <div :key="choice.id" v-for="choice in answerChoices" class="answer-choice">
      <div class="answer-choice-content sent"
        @click="answer(choice)">
        <div class="answer-choice-text">
          <p>{{ choice.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "answer-choices",
  props: [
    "answerChoices"
  ],
  data: function () {
    return {

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    answer(choice) {
      this.$emit("answer", choice)
    }
  },
}
</script>

<style lang="scss" scoped>
  .answer-choices {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 50px;
		padding-bottom: 80px;
  }
  .answer-choice-content {
    margin: 12px 12px;
    width: 250px;
    min-height: 75px;
    display: flex;
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
		@media screen and (max-width: 768px) {
			display: flex;
			min-height: 1px;
			margin: 4px 12px;
  	}
  }
  .answer-choice-text {
    padding: 12px 16px;
    border-radius: 6px;
    width: 100%;
  }
  .answer-choice-text p {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    word-wrap: break-word;
    margin: 0;
    font-family: arial;
  }
  .answer-choice-content.sent .answer-choice-text {
    color: white;
    background-color: #4e8cff;
    width: 100%;
    word-wrap: break-word;
  }
  .answer-choice-content.received .answer-choice-text {
    color: #263238;
    background-color: #f4f7f9;
    margin-right: 40px;
  }
</style>

