// import "bootstrap";
//= require quill.global

import Vue from 'vue'
import ChatApplication from "./apps/chat_app.vue"
import FlowBuilderApplication from "./apps/flow_builder.vue"
import QuestionEditorApplication from "./apps/question_editor.vue"
import NewEventApplication from "./apps/new_event.vue"
import VueConfirmDialog from 'vue-confirm-dialog'
import 'bootstrap';

// Load components

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.directive('input-mask', {
 bind: function(el) {
   new Inputmask({
     mask: $(el).attr('mask'),
   }).mask(el);
 },
});

// Load application

const appToBuild = document.querySelector("application") && document.querySelector("application").id

switch(appToBuild) {
  case "ChatApplication":
    new Vue(Object.assign({}, ChatApplication, {}));
    break;
  case "FlowBuilderApplication":
    new Vue(Object.assign({}, FlowBuilderApplication, {}));
    break;
  case "QuestionEditorApplication":
    new Vue(Object.assign({}, QuestionEditorApplication, {}));
    break;
  case "NewEventApplication":
    new Vue(Object.assign({}, NewEventApplication, {}));
    break;
  default:
    break;
}
