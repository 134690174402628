<template>
  <div>
    <vue-confirm-dialog class="vue-confirm-style"></vue-confirm-dialog>
    <div id="app-container">
      <div id="app-question-editor">
        <div id="app-header">
          <div id="app-header-title">
            Question Editor
          </div>
        </div>
        <div id="question-editor-container">
          <div class="container">
            <h1 class="mb-5">Editor de perguntas</h1>
            <div class="flow-field-div">
              <p class="field-label">Nome</p>
              <input class="flow-field" v-model="item.name" type="text">
            </div>

            <hr>

            <h2>Mensagens</h2>

            <draggable handle=".handle" v-model="item.messages">
              <div v-for="(message, index) in item.messages" :key="index" class="draggable mb-3">
                <div class="flex center around">
                  <i class="fa fa-align-justify handle"></i>
                  <p class="message-order mb-0">{{index + 1}}</p>
                  <input class="fill-field f-70" v-model="message.content" type="text">
                  <i class="fa fa-trash delete-icon ml-5 mt-4 f-10" @click="deleteMessage(index)"></i>
                </div>
              </div>
            </draggable>

            <p class="btn add-button" @click="addMessage">Adicionar mensagem</p>

            <hr>

            <h2>Opções</h2>

            <draggable handle=".handle" v-model="item.options">
              <div v-for="(option, index) in item.options" :key="index" class="question-message mb-5 draggable">
                <div class="flex center h-100">
                  <i class="fa fa-align-justify handle f-5"></i>
                  <p class="message-order mb-0">{{index + 1}}</p>
                  <input class="fill-field f-70 ml-5" v-model="option.content" type="text">
                  <p @click="toggleOptionShow(option)" class="delete-icon ml-5 f-5 mt-5">
                    <i class="fas bold fs-30 fa-caret-up" v-if="option.show"></i>
                    <i class="fas bold fs-30 fa-caret-down" v-else></i>
                  </p>
                  <i class="fa fa-trash delete-icon mt-4 f-5" @click="deleteOption(option)"></i>
                </div>

                <div v-show="option.show">
                  <div class="mb-2">
                    <h4>Direito</h4>
                    <textarea v-model="option.legal" class="textarea m-0" cols="30" rows="10"></textarea>
                  </div>

                  <div class="mb-2">
                    <h4>Dos pedidos</h4>
                    <textarea v-model="option.demand" class="textarea m-0" cols="30" rows="10"></textarea>
                  </div>

                  <div class="mb-2">
                    <h4>Resposta</h4>
                    <textarea v-model="option.reply" class="textarea m-0" cols="30" rows="10"></textarea>
                  </div>

                  <div class="mb-2">
                    <h4>Observações</h4>
                    <textarea v-model="option.comments" class="textarea m-0" cols="30" rows="10"></textarea>
                  </div>

									<div class="mb-2">
                    <h4>Descrição para usuário</h4>
                    <textarea v-model="option.user_description" class="textarea m-0" cols="30" rows="10"></textarea>
                  </div>
                </div>
              </div>
            </draggable>

            <p class="btn add-button mb-5" @click="addOption">Adicionar opção</p>

            <hr>

            <p class="btn btn-success mt-3 py-5 w-100 mb-5" @click="saveQuestion">Salvar pergunta</p>

            <p v-if="questionId" class="btn btn-danger mt-3 py-4 w-100 mb-3" @click="deleteQuestion">Apagar pergunta</p>

            <a href="/admin/questions" class="btn btn-secondary mt-3 py-4 w-100 mb-3">Cancelar e voltar para perguntas</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  el: "application",
  data: function () {
    return {
      question: null,
      renderMessages: 0,
      renderOptions: 0,
      renderQuestions: 0,
      tab: "settings",
      item: {
        name: "",
        messages: [
          {
            content: "Nova mensagem",
          }
        ],
        options: [
          {
            content: "Primeira opção",
            legal: "",
            demand: "",
            reply: "",
            comments: "",
						user_description: "",
            show: true,
          }
        ]
      }
    }
  },
  computed: {
    questionId() {
      return window.location.pathname.replace( /^\D+/g, '');
    },
    resourceUrl() {
      return `/client/questions/${this.questionId}`;
    },
  },
  mounted() {
    if (this.questionId) this.fetchQuestion();
  },
  components: {
    draggable,
  },
  methods: {
    async fetchQuestion() {
      const response = await axios.get(`${this.resourceUrl}/edit`);
      this.item = response.data.question;
    },
    async saveQuestion() {
      const params = Object.assign({}, this.item)
      params.messages = params.messages.map((m) => m.content)
      const response = await axios.patch(`${this.resourceUrl}`, params);
      if (response.data.success) {
        window.location="/admin/questions?confirm=true";
      } else {c
        alert(response.data.errors)
      }
    },
    addMessage() {
      this.item.messages.push({
        content: "Nova mensagem"
      })
    },
    deleteMessage(index) {
      this.item.messages.splice(index, 1)
    },
    toggleOptionShow(option) {
      option.show = !option.show;
    },
    addOption() {
      this.item.options.push(
        {
          content: "Nova opção",
          legal: "",
          demand: "",
          reply: "",
          comments: "",
          order: 0,
          show: true,
        }
      )
    },
    deleteOption(index) {
      this.$confirm({
        message: "Tem certeza que deseja apagar essa opção? As informações escritas serão perdidas",
        button: {
          no: 'Voltar',
          yes: 'Deletar',
        },
        callback: confirm => {
          if (confirm) {
            this.item.options.splice(index, 1)
          }
        }
      })
    },
    deleteQuestion(question) {
      this.$confirm({
        message: "Tem certeza que deseja apagar essa pergunta? Todas as informações serão perdidas.",
        button: {
          no: 'Voltar',
          yes: 'Deletar',
        },
        callback: confirm => {
          if (confirm) {
            this.proceedQuestionDelete();
          }
        }
      })
    },
    async proceedQuestionDelete() {
      const response = await axios.delete(`${this.resourceUrl}`);
      if (response.data.success) {
        window.location="/admin/questions?confirm=true";
      } else {
        alert(response.data.errors)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  #app-container {
    width: 100vw;
  }
  #app-icon img {
    width: 70px;
    position: fixed;
    bottom: 40px;
    right: 40px;
  }
  #app-question-editor {
    width: 100%;
  }
  #app-header {
    background: #4e8cff;
    height: 10vh;
    color: white;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0,0,0,.2);
    position: relative;
    box-sizing: border-box;
    display: flex;
  }
  #app-header-image {
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  #app-header-image img {
    height: 40px;
    border-radius: 50%;
  }
  #app-header-title {
    padding: 10px;
    padding-left: 30px;
    font-family: arial;
    font-size: 32px;
  }
  #app-header-close {
    align-self: center;
    padding: 12px 20px;
    margin-right: 10px;
    box-sizing: border-box;
    border-radius: 5px;
  }
  @media (max-width: 450px) {
    .sc-header {
      border-radius: 0px;
    }
  }
  #app-message-list {
    width: 100%;
  }

  .textarea {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    color: #161616;
    font-size: 1.5rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: rgb(251, 251, 251);
    border: 1px solid rgb(200,200,200);
    box-shadow: 2px 2px rgb(195,195,195);
    display: block;
    transition: all 0.3s;
  }












  .main-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .main-tab {
    width: 30%;
    background: white;
    padding: 20px 30px;
    border: 1px solid rgb(150,150,150);
    border-radius: 8px;
    color: rgb(40,40,40);
    font-size: 16px;
    text-transform: uppercase;
    margin-right: 10px;
    &:hover {
      background-color: #161616;
      color: white;
      cursor: pointer;
      transition: 200ms;
    }
    &.active {
      background-color: #161616;
      color: white;
    }
  }
  .main-tab-content {
    padding: 20px 12px;
    min-height: 400;
  }

  .add-button {
    margin-top: 20px;
    padding: 20px;
    display: block;
    border: 1px solid rgb(130,130,130);
    &:hover {
      background: rgb(130,130,130);
      color: white;
    }
  }



  #question-editor-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 200px;
    background-color: rgb(250,250,250);
  }
  .flow-info {
    padding: 20px 40px;
    background: white;
    border-radius: 6px;
    box-shadow: 1px 1px rgb(220,220,220);
    margin-bottom: 20px;
  }

  .flow-field-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    margin-bottom: 10px;
  }

  .field-label {
    flex: 0 0 20%;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .flow-field {
    flex: 0 0 80%;
    font-family: 'Roboto', sans-serif;
    color: #161616;
    font-size: 1.5rem;
    margin: 0 auto;
    padding: 1.5rem 4rem;
    border-radius: 1rem;
    background-color: rgb(251, 251, 251);
    border: 1px solid rgb(240,240,240);
    box-shadow: 1px 1px rgb(220,220,220);
    width: 90%;
    display: block;
    transition: all 0.3s;
  }


  .fill-field {
    font-family: 'Roboto', sans-serif;
    color: rgb(80,80,80);
    font-size: 1.4rem;
    padding: 1.2rem;
    border: none;
    border-bottom: 2px solid rgb(140,140,140);
    width: 80%;
    display: block;
  }

  .question {
    background: white;
    border-radius: 6px;
    box-shadow: 1px 1px rgb(220,220,220);
    margin-bottom: 10px;
  }

  .question-header {
    height: 80px;
    display: flex;
    background-color: #4e8cff;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
  }

  .question-identifier {
    margin-right: 30px;
    margin-bottom: 0;
    font-size: 40px;
    color: white;
  }

  .question-name {
    width: 50%;
  }
  .question-control {
    border: 1px solid rgb(230,230,230);
    background: white;
    padding: 10px;
    p {
      margin: 0;
      font-size: 30px;
    }
    &:hover {
      opacity: 0.9;
      transition: 200ms;
      cursor: pointer;
      background: rgb(240,240,240);
    }
  }
  .question-content {
    padding: 24px;
  }
  .question-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .question-tab {
    width: 40%;
    background: white;
    padding: 8px 12px;
    color: rgb(40,40,40);
    font-size: 16px;
    text-transform: uppercase;

    &:hover {
      border-bottom: 2px solid #161616;
      cursor: pointer;
    }
    &.active {
      border-bottom: 4px solid #161616;
    }
  }
  .question-tab-content {
    padding: 20px 12px;
    min-height: 200px;
  }


  .message-order {
    font-size: 28px;
    text-align: center;
    color: rgb(80,80,80);
  }
  .delete-icon {
    color: rgb(80,80,80);
    font-size: 20px;
    &:hover {
      color: rgb(20,20,20);
      cursor: pointer;
      transition: 300ms;
    }
  }
  .delete-icon-question {
    color: white;
    font-size: 28px;
    &:hover {
      color: rgb(200,200,250);
      cursor: pointer;
      transition: 200ms;
    }
  }

  .question-option {
    border: 1px solid rgb(150,150,150);
    border-radius: 8px;
    background: rgb(240,240,255);
    padding: 8px 30px 12px 30px;
    margin-bottom: 10px;
  }
  .option-order {
    font-size: 40px;
    text-align: center;
    color: rgb(80,80,80);
  }
  .option-top {

  }
  .option-bot {

  }
  .handle {
    font-size: 30px;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: 200ms;
    }
  }

  #document-text {
    min-height: 60vh;
    padding: 30px;
  }

  .document-controls-div {
    height: 60vh;
    overflow-y: scroll;
  }

  .each-control {
    border: 1px solid rgb(150,150,150);
    margin-bottom: 8px;
  }

  .control-header {
    padding: 8px;
    background-color: rgb(220,220,220);
    border-bottom: 2px solid rgb(200,200,200);
  }

  .control-options {
    padding: 8px;
    margin-bottom: 8px;
  }

  .each-option {
    padding: 8px;
    &:hover {
      cursor: pointer;
      background-color: rgb(200,200,200);
      transition: 300ms;
    }
  }

</style>

