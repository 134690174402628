<template>
  <div>
    <div class="app-message-content flex center"
      :class="message.sender === 'user' ? 'sent' : 'received'">
			<img :src="this.logoUrl" class="app-message-avatar" v-if="message.sender !== 'user'">
      <div class="app-message-text">
        <p>{{ message.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "message",
  props: [
    "message"
  ],
  data: function () {
    return {

    }
  },
	computed: {
		logoUrl() {
			return document.querySelector("#logo-url").innerHTML;
		},
	},
  methods: {

  },
}
</script>

<style lang="scss" scoped>
  .app-message-content {
    margin: 6px 10px;
    width: 100%;
  }
  .question-active {
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
  .app-message-content.sent {
    justify-content: flex-end;
  }
  .app-message-content.sent .app-message-avatar {
    display: none;
  }
  .app-message-avatar {
		border-radius: 50%;
		height: 60px;
    margin-right: 15px;
  }
  .app-message-text {
    padding: 18px 20px;
    border-radius: 6px;
    width: calc(50% - 140px);
  }
  @media screen and (max-width: 1200px) {
    .app-message-text {
      width: calc(50%);
    }
  }
  @media screen and (max-width: 768px) {
    .app-message-text {
      width: calc(80%);
    }

		.app-message-avatar {
			height: 40px;
		}
  }
  .app-message-text p {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    word-wrap: break-word;
    margin: 0;
    font-family: arial;
  }
  .app-message-content.sent .app-message-text {
    color: white;
    background-color: #4e8cff;
    max-width: calc(100% - 120px);
    word-wrap: break-word;
  }
  .app-message-content.received .app-message-text {
    color: #263238;
    background-color: #f4f7f9;
    margin-right: 40px;
  }
  @media (max-width: 450px) {
    .app-message {
      width: 80%;
    }
  }
</style>
