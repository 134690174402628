<template>
  <div>
    <vue-confirm-dialog class="vue-confirm-style"></vue-confirm-dialog>
    <div id="app-container">
      <div id="app-flow-builder">
        <div id="app-header">
          <div id="app-header-title">
            FlowBuilder
          </div>
        </div>
        <div id="flow-builder-container">
          <div class="container mt-5">
            <h1 class="mb-5">Editor de fluxos</h1>
            <div class="flow-info mt-5">
              <div class="flow-field-div">
                <p class="field-label">Nome do fluxo</p>
                <input class="flow-field" v-model="item.name" type="text"
                  :class="{ error: errors.includes('name') }">
              </div>
              <div class="flow-field-div">
                <p class="field-label">Descrição do fluxo</p>
                <input class="flow-field" v-model="item.description" type="text"
                  :class="{ error: errors.includes('description') }">
              </div>
              <div class="flow-field-div">
                <p class="field-label">Código da lei</p>
                <input class="flow-field" v-model="item.law" type="text">
              </div>
              <div class="flow-field-div">
                <p class="field-label">Severidade</p>
                <select class="flow-field" v-model="item.severity" type="text">
                  <option value="leve">Leve</option>
                  <option value="media">Média</option>
                  <option value="grave">Grave</option>
                  <option value="gravissima">Gravíssima</option>
                </select>
              </div>
            </div>

            <h2>Seleção de perguntas</h2>
            <p class="btn btn-secondary mb-5" @click="selectAll">Selecionar todas</p>
            <draggable v-model="questions">
              <div v-for="(question, index) in questions" :key="index" class="draggable mb-3">
                <div class="flex center start question" :class="{ selected: question.selected }"
                  @click="toggleSelected(question)">
                  <p class="m-0">{{question.name}}</p>
                </div>
              </div>
            </draggable>

            <hr>

            <p class="btn btn-success mt-3 py-5 w-100 mb-5" @click="saveFlow">Salvar fluxo</p>

            <a href="/admin/flows" class="btn btn-secondary mt-3 py-5 w-100 mb-5">Cancelar e voltar para fluxos</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  el: "application",
  data: function () {
    return {
      item: {
        name: "Novo fluxo",
        description: "Descrição",
        law: "Código da lei",
        severity: "",
      },
      questions: [],
      errors: [],
      renderMessages: 0,
      renderOptions: 0,
      renderQuestions: 0,
      tab: "settings",
    }
  },
  computed: {
    flowId() {
      return window.location.pathname.replace( /^\D+/g, '');
    },
    resourceUrl() {
      return `/client/flows${this.flowId ? `/${this.flowId}` : ""}`;
    },
  },
  mounted() {
    this.fetchFlow();
  },
  components: {
    draggable,
  },
  methods: {
    async fetchFlow() {
      const response = await axios.get(`${this.resourceUrl}/edit`);
      console.log(response.data)
      this.item = response.data.flow
      this.questions = response.data.questions
    },
    async saveFlow() {
      const params = {...this.item, questions: this.questions}
      const response = await axios.patch(`${this.resourceUrl}`, params);
      this.errors = response.data.errors
      if (response.data.success) {
        window.location="/admin/flows?confirm=true";
      }
    },
    toggleSelected(question) {
      question.selected = !question.selected;
    },
    selectAll() {
      this.questions.forEach((question) => {
        question.selected = true;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  #app-container {
    height: 100vh;
    width: 100vw;
  }
  #app-icon img {
    width: 70px;
    position: fixed;
    bottom: 40px;
    right: 40px;
  }
  #app-flow-builder {
    height: 100%;
    width: 100%;
  }
  #app-header {
    background: #4e8cff;
    height: 10vh;
    color: white;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0,0,0,.2);
    position: relative;
    box-sizing: border-box;
    display: flex;
  }
  #app-header-image {
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  #app-header-image img {
    height: 40px;
    border-radius: 50%;
  }
  #app-header-title {
    padding: 10px;
    padding-left: 30px;
    font-family: arial;
    font-size: 32px;
  }
  #app-header-close {
    align-self: center;
    padding: 12px 20px;
    margin-right: 10px;
    box-sizing: border-box;
    border-radius: 5px;
  }
  @media (max-width: 450px) {
    .sc-header {
      border-radius: 0px;
    }
  }
  #app-message-list {
    width: 100%;
  }

  .flow-field-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    margin-bottom: 10px;
  }

  .field-label {
    flex: 0 0 20%;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .flow-field {
    flex: 0 0 80%;
    font-family: 'Roboto', sans-serif;
    color: #161616;
    font-size: 1.5rem;
    margin: 0 auto;
    padding: 1.5rem 4rem;
    border-radius: 1rem;
    background-color: rgb(251, 251, 251);
    border: 1px solid rgb(240,240,240);
    box-shadow: 1px 1px rgb(220,220,220);
    width: 90%;
    display: block;
    transition: all 0.3s;

    &.error {
      border: 1px solid #ff6666;
    }
  }

  .fill-field {
    font-family: 'Roboto', sans-serif;
    color: rgb(80,80,80);
    font-size: 1.4rem;
    padding: 1.2rem;
    border: none;
    border-bottom: 2px solid rgb(140,140,140);
    width: 80%;
    display: block;
  }

  .question {
    border: 1px solid rgb(220,220,220);
    padding: 8px 20px;

    p {
      font-size: 18px;
    }

    &:hover {
      background-color: #4e8cff;
      transition: 300ms;
      color: white;
      cursor: pointer;
    }

    &.selected {
      background-color: #4e8cff;
      color: white;
      border: 1px solid transparent;
    }
  }


</style>

